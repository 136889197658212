<template>
  <div>
    <a-card>
      <template slot="title">
          <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <strong>List Transaksi</strong>
            </div>
          </div>
        </template>
        <strong>Filter</strong>
        <br>
        <a-range-picker @change="onChange" style="width: 250px;" :default-value="[moment(), moment()]"/>
        <!-- <a-input class="" placeholder="tgl1" style="width: 120px;"></a-input>
        <a-input class="ml-2" placeholder="tgl2" style="width: 120px;"></a-input> -->
        <a-input class="ml-2" v-model="datasend.id_transaksi" placeholder="Id Transaksi" style="width: 120px;"></a-input>
        <a-input class="ml-2" v-model="datasend.id_produk" placeholder="Id Produk" style="width: 120px;"></a-input>
        <a-input class="ml-2" v-model="datasend.idpel" placeholder="Idpel" style="width: 120px;"></a-input>
        <a-input class="ml-2" v-model="datasend.limit" placeholder="Limit" style="width: 120px;"></a-input>
        <a-input class="ml-2" v-model="datasend.ref1" placeholder="Ref1" style="width: 120px;"></a-input>
        <a-button class="btn btn-outline-primary ml-2" @click="getData">Filter</a-button>
        <ag-grid-vue
          style="height: 50vh;"
          :class="
            settings.theme === 'dark'
              ? 'ag-theme-balham-dark mt-2'
              : 'ag-theme-balham mt-2'
          "
          :key="componentKey"
          :gridOptions="gridOptions"
          :columnDefs="columnDefs"
          :pagination="allowPagination"
          :paginationPageSize="15"
          :cacheQuickFilter="true"
          :quickFilterText="tablequickfilter"
          :accentedSort="false"
          :sortingOrder="['asc', 'desc']"
          :rowData="rowData"
          @grid-ready="onGridReady"
          @first-data-rendered="onFirstDataRendered"
        >
        </ag-grid-vue>
    </a-card>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['settings', 'user']),
  },
  created () {
    this.getUid()

    this.gridOptions = {}
    this.gridApi = this.gridOptions.api
    this.gridColumnApi = this.gridOptions.columnApi
  },
  data() {
    return {
      tablequickfilter: '',
      gridOptions: null,
      datasend: {
        method: 'rajabiller.list_transaksi',
        uid: '',
        pin: '',
        tgl1: '',
        tgl2: '',
        id_transaksi: '',
        id_produk: '',
        idpel: '',
        limit: '10',
        ref1: '',
      },
      columnDefs: [
        {
          field: 'REF2',
          headerName: 'REF2',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'TRANSACTION_DATE_TIME',
          headerName: 'TRANSACTION DATE TIME',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'ID_PRODUK',
          headerName: 'ID PRODUK',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'NAMA_PRODUK',
          headerName: 'NAMA PRODUK',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'IDPEL',
          headerName: 'IDPEL',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'STATUS',
          headerName: 'STATUS',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'KET',
          headerName: 'KET',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'NOMINAL',
          headerName: 'NOMINAL',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'SN',
          headerName: 'SN',
          filter: true,
          sortable: true,
          resizable: true,
        },
        {
          field: 'STATUS_TRX',
          headerName: 'STATUS TRX',
          filter: true,
          sortable: true,
          resizable: true,
        },
      ],
      selectedData: null,
      gridApi: null,
      rowData: [],
      componentKey: 0,
      allowPagination: localStorage.getItem('gridpagination') || false,
    }
  },
  methods: {
    moment,
    async getUid() {
      var res = await lou.customUrlGet2('uid')
      if (res) {
        // console.log('res.data.uid', res.data.uid)
        // console.log('this.settings.tempAppData.uid', this.settings.tempAppData.uid)
        this.settings.tempAppData = { uid: '', pin: '' }
        this.settings.tempAppData.uid = res.data === undefined || res.data === null ? '' : res.data.uid === undefined || res.data.uid === null ? '' : res.data.uid
        this.settings.tempAppData.pin = res.data === undefined || res.data === null ? '' : res.data.pin === undefined || res.data.pin === null ? '' : res.data.pin
        // console.log('this.settings.tempAppData', this.settings.tempAppData)
        this.datasend.uid = this.settings.tempAppData.uid
        this.datasend.pin = this.settings.tempAppData.pin
        this.datasend.tgl1 = moment().format('YYYYMMDD') + '000000'
        this.datasend.tgl2 = moment().format('YYYYMMDD') + '230000'
        this.getData()
      }
    },
    onChange(date, dateString) {
      // console.log(date, dateString)
      var date1 = date[0].format('YYYYMMDD')
      var date2 = date[1].format('YYYYMMDD')

      this.datasend.tgl1 = date1 + '000000'
      this.datasend.tgl2 = date2 + '230000'
      this.getData()
    },
    async getData() {
      var res = await lou.customUrlPost2('', this.datasend, false, false, true)
      if (res.data.STATUS === '00') {
        if (res.data.RESULT_TRANSAKSI) {
          var tempArr = []
          res.data.RESULT_TRANSAKSI.forEach(element => {
            // var da = this.splitData(element)
            var da = element
            tempArr.push(da)
          })
          this.rowData = this.$g.clone(tempArr)
        } else {
          lou.shownotif('Kosong', 'Data memang kosong/tidak ada!')
        }
      } else {
        lou.shownotif('Error', res.data.KET, 'error')
      }
    },
    onGridReady(params) {
      // params.api.sizeColumnsToFit()
      this.gridApi = params.api
      this.gridColumnApi = params.columnApi
    },
    onFirstDataRendered() {
      this.autoSizeAll(false, { autosizecolumn: true })
    },
    autoSizeAll(skipHeader, { autosizecolumn = true }) {
      if (autosizecolumn) {
        var allColumnIds = []
        // console.log('this.gridColumnApi', this.gridColumnApi)
        this.gridColumnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId)
        })
        this.gridColumnApi.autoSizeColumns(allColumnIds, skipHeader)
      } else {
        this.gridApi.sizeColumnsToFit()
      }
    },
    splitData(data) {
      const fields = data.split('#')

      const result = {
        NomorTransaksi: fields[0],
        WaktuTransaksi: fields[1],
        KodeLayanan: fields[2],
        NamaLayanan: fields[3],
        NomorKartu: fields[4],
        KodeStatus: fields[5],
        StatusTransaksi: fields[6],
        NomorReferensi: fields[7],
        HashCode: fields[8],
        StatusProses: fields[9],
      }

      return result
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
